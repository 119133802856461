import {combineReducers} from 'redux';
import actionTypes from '../action_types';
import { toMap, cipRevenueToMap } from '../../../until/toMap';

function cips (state = { saved: { isSaved: false, isSuccess: false}, cips: {}}, action) {
    switch (action.type) {
        case actionTypes.GET_CIPS: 
            return { ...state, cips: toMap(action.data)};
        case actionTypes.SAVE_CIPS_REQUEST:
            return { ...state, saved: { isSaved: false, isSuccess: false }};
        case actionTypes.SAVE_CIPS_SUCCESS:
            return { ...state, saved: { isSaved: true, isSuccess: true }};
        case actionTypes.SAVE_CIPS_FAIL:
            return { ...state, saved: { isSaved: true, isSuccess: false }};
        default:
            return state;
    }
    return state;
}

function partners (state = {isSaved: false, partners: {}}, action) {
    switch (action.type) {
        case actionTypes.GET_PARTNERS:
            return { ...state, partners: toMap(action.data)};
        case actionTypes.ADD_PARTNER_REQUEST:
            return { ...state, isSaved: false }
        case actionTypes.ADD_PARTNER: {
            return { partners: toMap(action.data), isSaved: true};
        }
        case actionTypes.REMOVE_PARTNER: {
            return { ...state, partners: toMap(action.data)};
        }
        case actionTypes.EDIT_PARTNER: {
            return { }
        }
        default:
            return state;
    }
    return state;
}

function revenues (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_CIP_REVENUES: 
            return cipRevenueToMap(action.data);
        default:
            return state;
    }
    return state;
}

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {

        case actionTypes.EDIT_PARTNER_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EDIT_PARTNER_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EDIT_PARTNER_FAIL: 
            return { isSaved: true, isSuccess: false }

        default:
            return state;
    }
    return state;
}

export default combineReducers({
    cips,
    partners,
    revenues,
    actions
})