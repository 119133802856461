import Client from '../client';
import actionTypes from '../action_types';

export function getServices() {
    return async (dispatch) => {
        try {
            const data = await Client.getServices();
            
            dispatch({
                type: actionTypes.GET_SERVICES,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getServiceGroups() {
    return async (dispatch) => {
        try {
            const data = await Client.getServiceGroups();
            dispatch({
                type: actionTypes.GET_SERVICE_GROUPS,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function saveRevenues(shopId, info, date, numCustomer) {
    
    const infoList = Object.keys(info).map(key => {      
        return {
            service_id: parseInt(key),
            revenue: info[key],   
            date,
        };
    });

    const infoCustomer = [{
        num_customer: numCustomer,
        date,
    }];

    return async (dispatch) => {
        dispatch({
            type: actionTypes.SAVE_REVENUE_REQUEST,
        }) 
        dispatch({
            type: actionTypes.SAVE_CUSTOMER_REQUEST,
        }) 
        try {
            // const data = await Client.saveRevenues(shopId, infoList);
            const promises = [
                Client.saveRevenues(shopId, infoList),
                Client.saveCustomerOfShop(shopId, infoCustomer)
            ]

            Promise.all(promises)
            .then(values => {
                if (values) {
                    if (values[0]) {
                        dispatch({
                            type: actionTypes.SAVE_REVENUE_SUCCESS,
                            data: values[0]
                        })    
                    } else {
                        dispatch({
                            type: actionTypes.SAVE_REVENUE_FAIL,
                        }) 
                    }

                    if (values[1]) {
                        dispatch({
                            type: actionTypes.SAVE_CUSTOMER_SUCCESS,
                            data: values[1]
                        })  
                    } else {
                        dispatch({
                            type: actionTypes.SAVE_CUSTOMER_FAIL,
                        })
                    }
                }
            })
            
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function addServiceGroup(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_SERVICE_GROUP_REQUEST,
        })
        try {
            const dt = await Client.addServiceGroup(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.ADD_SERVICE_GROUP_SUCCESS,
                })
                const data = await Client.getServiceGroups();
                dispatch({
                    type: actionTypes.GET_SERVICE_GROUPS,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.ADD_SERVICE_GROUP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function editServiceGroup(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.EDIT_SERVICE_GROUP_REQUEST,
        })
        try {
            const dt = await Client.editServiceGroup(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.EDIT_SERVICE_GROUP_SUCCESS,
                })
                const data = await Client.getServiceGroups();
                dispatch({
                    type: actionTypes.GET_SERVICE_GROUPS,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.EDIT_SERVICE_GROUP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function deleteServiceGroup(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SERVICE_GROUP_REQUEST,
        })
        try {
            const dt = await Client.deleteServiceGroup(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.DELETE_SERVICE_GROUP_SUCCESS,
                })
                const promises = [
                    Client.getServiceGroups(),
                    Client.getServices(),
                ]
                Promise.all(promises)
                .then(values => {
                    if (values[0]) {
                        dispatch({
                            type: actionTypes.GET_SERVICE_GROUPS,
                            data: values[0],
                        })
                    }
                    if (values[1]) {
                        dispatch({
                            type: actionTypes.GET_SERVICES,
                            data: values[1],
                        })

                    }
                })
            } else {
                dispatch({
                    type: actionTypes.DELETE_SERVICE_GROUP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function addService(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_SERVICE_REQUEST,
        })
        try {
            const dt = await Client.addService(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.ADD_SERVICE_SUCCESS,
                })
                const data = await Client.getServices();
                dispatch({
                    type: actionTypes.GET_SERVICES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.ADD_SERVICE_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function editService(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.EDIT_SERVICE_REQUEST,
        })
        try {
            const dt = await Client.editService(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.EDIT_SERVICE_SUCCESS,
                })
                const data = await Client.getServices();
                dispatch({
                    type: actionTypes.GET_SERVICES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.EDIT_SERVICE_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function deleteService(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SERVICE_REQUEST,
        })
        try {
            const dt = await Client.deleteService(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.DELETE_SERVICE_SUCCESS,
                })
                const data = await Client.getServices();
                dispatch({
                    type: actionTypes.GET_SERVICES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.DELETE_SERVICE_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}