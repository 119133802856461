import { combineReducers } from 'redux';
import App from '@mins/redux/app/reducer';
import Auth from '@mins/redux/auth/reducer';
import ThemeSwitcher from '@mins/redux/themeSwitcher/reducer';
import Ecommerce from '@mins/redux/ecommerce/reducer';
import LanguageSwitcher from '@mins/redux/languageSwitcher/reducer';

import Cias from './cias/reducers'

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
  Cias,
});
