
 
const isLoggedIn = () => {
    return localStorage.getItem('token') || sessionStorage.getItem('token')
}

const setUser = (user, auth_token, isLocal) => {
    const { id, name } = user;

    if (isLocal) {
        localStorage.setItem('user_id', id);
        localStorage.setItem('name', name);
        localStorage.setItem('token', auth_token);
    } else {
        sessionStorage.setItem('user_id', id);
        sessionStorage.setItem('name', name);
        sessionStorage.setItem('token', auth_token);
    }
}

const setName = (name) => {
    localStorage.setItem('name', name);
    sessionStorage.setItem('name', name);
}

const getToken = () => {
    if (localStorage.getItem('token')) {
        return localStorage.getItem('token');
    }
    return sessionStorage.getItem('token');
}

const getUserId = () => {
    if (localStorage.getItem('user_id')) {
        return localStorage.getItem('user_id');
    }
    return sessionStorage.getItem('user_id');
}

const getName = () => {
    if (localStorage.getItem('name')) {
        return localStorage.getItem('name');
    }
    return sessionStorage.getItem('name');
}

const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('user_id');

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('user_id');
}

const login = (user, auth_token, isLocal) => {
    setUser(user, auth_token, isLocal);
}

export default {
    login,
    logout,
    isLoggedIn,
    setName,
    getToken,
    getUserId,
    getName,
}