import { store } from './store';
import authActions from '@mins/redux/auth/actions';
import { getAllShops } from './cias/actions/shop';
import { getBranches } from './cias/actions/branch';
import { getServices, getServiceGroups } from './cias/actions/service';
import { getUser } from './cias/actions/auth';
import Auth from '../components/Auth';

export default () => {
  if (Auth.getToken()) {
    return new Promise(() => {
      store.dispatch(getBranches());
      store.dispatch(getAllShops());
      store.dispatch(getServices());
      store.dispatch(getServiceGroups());  
      store.dispatch(getUser(Auth.getUserId()));
    });
  }
  return new Promise(() => {});
}
