import actionTypes from '../action_types';
import {combineReducers} from 'redux';

function roles (state = "", action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return action.data.user.roles;
        case actionTypes.GET_USER:
            return action.data.roles;
        default:
            return state;
    }
    return state;
}

function branchRoles (state = {}, action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
            const branchRoles = action.data.user.branch_roles;
            let roles = {};
            branchRoles.map(role => {
                roles[role.branch_id] = role.roles;
            })

            return roles;
        }
        case actionTypes.GET_USER:{
            const branchRoles = action.data.branch_roles;
            let roles = {};
            branchRoles.map(role => {
                roles[role.branch_id] = role.roles;
            })
            return roles;
        }
        default:
            return state;
    }
    return state;
}

function branchId (state = 0, action) {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
            const branchRoles = action.data.user.branch_roles;
            let id = branchRoles[0] ? branchRoles[0].branch_id : 0;
            return id;
        }
        case actionTypes.GET_USER:{
            const branchRoles = action.data.branch_roles;
            let id = branchRoles[0] ? branchRoles[0].branch_id : 0;
            return id;
        }
        case actionTypes.CHANGE_BRANCH_ID: {
            return action.data
        }
        default:
            return state;
    }
    return state;
}
export default combineReducers({
    roles,
    branchRoles,
    branchId,
})