import {combineReducers} from 'redux';
import actionTypes from '../action_types';
import { toMap } from '../../../until/toMap';

function services (state = {saved: { isSaved: false, isSuccess: false}, services: {}}, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICES: {
            return { ...state, services: toMap(action.data)};
        }
        case actionTypes.SAVE_REVENUE_REQUEST: {
            return { ...state, saved: {isSaved: false}};
        }
        case actionTypes.SAVE_REVENUE_SUCCESS: {
            return { ...state, saved: { isSaved: true, isSuccess: true}};
        }
        case actionTypes.SAVE_REVENUE_FAIL: {
            return { ...state, saved: { isSaved: true, isSuccess: false }};
        }
        return {...state};
    }
    return state;
}

// function info (state = { isSaved: false }, action) {
//     switch (action.type) {
//         case actionTypes.SAVE_REVENUE_SUCCESS: {
//             return { isSaved: true };
//         }
//         return state;
//     }
//     return state;
// }

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {
        case actionTypes.ADD_SERVICE_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.ADD_SERVICE_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.ADD_SERVICE_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.EDIT_SERVICE_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EDIT_SERVICE_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EDIT_SERVICE_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.DELETE_SERVICE_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.DELETE_SERVICE_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.DELETE_SERVICE_FAIL: 
            return { isSaved: true, isSuccess: false }
        default:
            return {...state};
    }
    return state;
}

export default combineReducers({
    services,
    actions
    // info,
})