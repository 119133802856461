import Client from '../client';
import actionTypes from '../action_types';

export function getBranches() {
    return async (dispatch) => {
        try {
            const data = await Client.getBranches();
            dispatch({
                type: actionTypes.GET_BRANCHES,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getBranchTarget(branchId, month) {
    return async (dispatch) => {
        try {
            const data = await Client.getBranchTarget(branchId, month);
            dispatch({
                type: actionTypes.GET_BRANCH_TARGET,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function saveTarget(branchId, targets, month) {
    const array = Object.keys(targets).map(key => {
        return {
            target: targets[key],
            shop_id: key,
            month,
        }
    })
    return async (dispatch) => {
        dispatch({
            type: actionTypes.SAVE_BRANCH_TARGET_REQUEST,
        })
        try {
            await Client.saveTarget(array);
            const data = await Client.getBranchTarget(branchId, month);
            dispatch({
                type: actionTypes.SAVE_BRANCH_TARGET,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getRevenueOfBranch(branchId, date) {
    return async (dispatch) => {
        try {
            const data = await Client.getRevenueOfBranch(branchId, date);
            dispatch({
                type: actionTypes.GET_REVENUE_OF_BRANCH,
                data,
            })
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function addBranch(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_BRANCH_REQUEST,
        })
        try {
            const dt = await Client.addBranch(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.ADD_BRANCH_SUCCESS,
                })
                const data = await Client.getBranches();
            
                dispatch({
                    type: actionTypes.GET_BRANCHES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.ADD_BRANCH_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function editBranch(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.EDIT_BRANCH_REQUEST,
        })
        try {
            const dt = await Client.editBranch(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.EDIT_BRANCH_SUCCESS,
                })
                const data = await Client.getBranches();
            
                dispatch({
                    type: actionTypes.GET_BRANCHES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.EDIT_BRANCH_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function deleteBranch(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_BRANCH_REQUEST,
        })
        try {
            const dt = await Client.deleteBranch(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.DELETE_BRANCH_SUCCESS,
                })
                const data = await Client.getBranches();
            
                dispatch({
                    type: actionTypes.GET_BRANCHES,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.DELETE_BRANCH_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}