import actionTypes from '../action_types';
import {combineReducers} from 'redux';
import { toMap } from '../../../until/toMap';

function partners(state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_LIST_PARTNERS:
            return toMap(action.data);
        default:
            return state;
    }
    return state;
}

export default combineReducers({
    partners
})
