import {combineReducers} from 'redux';
import actionTypes from '../action_types';
import { toMap   } from '../../../until/toMap';

function shops (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_OTHER_SHOPS: 
            return toMap(action.data);
        default:
            return state;
    }
    return state;
}

function services (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICES_OF_OTHER_SHOP: 
        
            return action.data;
        default: 
            return state;
    }
    return state;
}

function revenues (state = {saved: { isSaved: false, isSuccess: false }, revenues: {}, getRevenue: false}, action) {
    switch (action.type) {
        case actionTypes.GET_REVENUE_OF_OTHER_SHOP_REQUEST: 
            return { ...state, revenues: {}, getRevenue: false};
        case actionTypes.GET_REVENUE_OF_OTHER_SHOP_SUCCESS: {
            action.data.map(a => {
                const { shop_id } = a;
                state.revenues[shop_id] = a;
            })
            return { ...state, getRevenue: true};
        }
        case actionTypes.SAVE_OTHER_REVENUE_REQUEST: 
            return { ...state, saved: {isSaved: false, isSuccess: false  }}
        case actionTypes.SAVE_OTHER_REVENUE_SUCCESS: {
            action.data.map(a => {
                const { shop_id } = a;
                state.revenues[shop_id] = a;
            })
            return { ...state, saved: {isSaved: true, isSuccess: true  }};
        }
        case actionTypes.SAVE_OTHER_REVENUE_REQUEST: 
            return { ...state, saved: {isSaved: true, isSuccess: false  }}
        default: {
            return state;     
        }
    }
    return state;
}

export default combineReducers({
    shops,
    services,
    revenues
})