import actionTypes from '../action_types';
import {combineReducers} from 'redux';

function flights (state = { saved: { isSaved: false, isSuccess: false}, info: {}}, action) {
    switch (action.type) {
        case actionTypes.GET_FLIGHT:
            return { ...state, info: action.data}
        case actionTypes.SAVE_FLIGHT_REQUEST:
            return { ...state, saved: { isSaved: false }};
        case actionTypes.SAVE_FLIGHT_SUCCESS:
            return { saved: { isSaved: true, isSuccess: true }, info: action.data[0] };
        case actionTypes.SAVE_FLIGHT_FAIL:
            return { ...state, saved: { isSaved: true, isSuccess: false }};
        default:
            return state;
    }

    return state;
}

export default combineReducers({
    flights,
})