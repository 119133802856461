import {combineReducers} from 'redux';
import branches from './branch';
import shops from './shop';
import flights from './flight';
import services from './service';
import serviceGroups from './serviceGroups';
import cips from './cip';
import partners from './partner';
import dashboard from './dashboard';
import otherShops from './otherShop';
import auth from './auth';
import role from './role';
import error from './error';
import setting from './setting';

export default combineReducers({
    auth,
    role,
    branches,
    shops,
    cips,
    partners,
    flights,
    services,
    serviceGroups,
    dashboard,
    otherShops,
    error,
    setting
})
