import {combineReducers} from 'redux';
import actionTypes from '../action_types';
import { toMap } from '../../../until/toMap';

function serviceGroups (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICE_GROUPS: {
            return toMap(action.data);
        }
        return state;
    }
    return state;
}

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {
        case actionTypes.ADD_SERVICE_GROUP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.ADD_SERVICE_GROUP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.ADD_SERVICE_GROUP_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.EDIT_SERVICE_GROUP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EDIT_SERVICE_GROUP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EDIT_SERVICE_GROUP_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.DELETE_SERVICE_GROUP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.DELETE_SERVICE_GROUP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.DELETE_SERVICE_GROUP_FAIL: 
            return { isSaved: true, isSuccess: false }
        default:
            return {...state};
    }
    return state;
}

export default combineReducers({
    serviceGroups,
    actions,
})