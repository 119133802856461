import {combineReducers} from 'redux';
import Auth from '../../../components/Auth';

function error (state = false, action) {
    switch (action.type) {
        case 'ERROR': {
            if (Auth.getToken()) {
                return true;
            }
            return state;
        }
        default:
            return state;
    }
    return state;
}

export default combineReducers({
    error
})