import Client from '../client';
import actionTypes from '../action_types';
import Auth from '../../../components/Auth';

export function login(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.LOGIN_REQUEST,
        })
        try {
            const data = await Client.login(info);
            
            if (data) {
                const { status } = data;
                if (status === 'success') {
                    dispatch({
                        type: actionTypes.LOGIN_SUCCESS,
                        data,
                    })
                } else {
                    dispatch({
                        type: actionTypes.LOGIN_FAIL,
                        data,
                    })
                }
            } else {
                dispatch({
                    type: actionTypes.LOGIN_FAIL,
                    data: {}
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}


export function logout() {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.LOGOUT_REQUEST,
        })
        try {
            const data = await Client.logout();
            
            if (data && data.status === 'success') {
                dispatch({
                    type: actionTypes.LOGOUT_SUCCESS,
                })
            } else {
                dispatch({
                    type: actionTypes.LOGOUT_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getAllUser() {
    return async (dispatch) => {
        try {
            const data = await Client.getAllUser();
            
            if (Array.isArray(data)) {
                dispatch({
                    type: actionTypes.GET_ALL_USER,
                    data,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getUser(id) {
    return async (dispatch) => {
        try {
            const data = await Client.getUser(id);
            
            if (data) {
                dispatch({
                    type: actionTypes.GET_USER,
                    data,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function createUser(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CREATE_USER_REQUEST
        })
        try {
            const user = await Client.createUser(info);
            
            if (user.status === 'success') {
                dispatch({
                    type: actionTypes.CREATE_USER_SUCCESS
                })
                const data = await Client.getAllUser();
            
                if (Array.isArray(data)) {
                    dispatch({
                        type: actionTypes.GET_ALL_USER,
                        data,
                    })
                }
            } else {
                dispatch({
                    type: actionTypes.CREATE_USER_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function changeRole(info, id) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CHANGE_ROLE_REQUEST
        })
        try {
            const user = await Client.changeRole(info, id);
            
            if (user && user.id) {
                dispatch({
                    type: actionTypes.CHANGE_ROLE_SUCCESS
                })
                const data = await Client.getAllUser();
            
                if (Array.isArray(data)) {
                    dispatch({
                        type: actionTypes.GET_ALL_USER,
                        data,
                    })
                }
            } else {
                dispatch({
                    type: actionTypes.CHANGE_ROLE_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function editUser(info, roles, id) {
    const userId = Auth.getUserId();
    return async (dispatch) => {
        dispatch({
            type: actionTypes.EDIT_USER_REQUEST
        })
        try {
            const promises = [
                Client.changeRole(roles, id),
                Client.editUser(info, id)
            ]
            // const user = await Client.editUser(info, id);
            
            Promise.all(promises)
            .then(values => {
                if (values.indexOf(undefined) === -1) {
                    dispatch({
                        type: actionTypes.EDIT_USER_SUCCESS
                    })
                    
                    let pros = [Client.getAllUser()]
                    if (id === userId) {
                        pros.push(Client.getUser(id))
                    }

                    Promise.all(pros)
                    .then(values => {
                        if (values.indexOf(undefined) === -1) {
                            dispatch({
                                type: actionTypes.GET_ALL_USER,
                                data: values[0],
                            })
                            if (id === userId) {
                                dispatch({
                                    type: actionTypes.GET_USER,
                                    data: values[1],
                                })
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: actionTypes.EDIT_USER_FAIL
                    })
                }
            })
            // if (user && user.id) {
            //     dispatch({
            //         type: actionTypes.EDIT_USER_SUCCESS
            //     })
                
            //     const data = await Client.getAllUser();
            
            //     if (Array.isArray(data)) {
            //         dispatch({
            //             type: actionTypes.GET_ALL_USER,
            //             data,
            //         })
            //     }
            // } else {
            //     dispatch({
            //         type: actionTypes.EDIT_USER_FAIL
            //     })
            // }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function deleteUser(id) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_USER_REQUEST
        })
        try {
            const user = await Client.deleteUser(id);
            
            if (user) {
                if (user.id) {
                    dispatch({
                        type: actionTypes.DELETE_USER_SUCCESS
                    })
                    const data = await Client.getAllUser();
                
                    if (Array.isArray(data)) {
                        dispatch({
                            type: actionTypes.GET_ALL_USER,
                            data,
                        })
                    }
                } else {
                    dispatch({
                        type: actionTypes.DELETE_USER_FAIL,
                        data: user
                    })
                }
            } else {
                dispatch({
                    type: actionTypes.DELETE_USER_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function activateUser(id) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ACTIVATE_USER_REQUEST
        })
        try {
            const user = await Client.activateUser(id);
            
            if (user && user.id) {
                dispatch({
                    type: actionTypes.ACTIVATE_USER_SUCCESS
                })
                const data = await Client.getAllUser();
            
                if (Array.isArray(data)) {
                    dispatch({
                        type: actionTypes.GET_ALL_USER,
                        data,
                    })
                }
            } else {
                dispatch({
                    type: actionTypes.ACTIVATE_USER_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function changePassword(info, id) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CHANGE_PASSWORD_REQUEST
        })
        try {
            const user = await Client.changePassword(info,id);
            
            if (user && user.id) {
                dispatch({
                    type: actionTypes.CHANGE_PASSWORD_SUCCESS
                })

            } else {
                dispatch({
                    type: actionTypes.CHANGE_PASSWORD_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function forgetPassword(email) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.FORGET_PASSWORD_REQUEST
        })
        try {
            const user = await Client.forgetPassword(email);
            
            if (user && user.token) {
                // localStorage.setItem('token', user.token);
                dispatch({
                    type: actionTypes.FORGET_PASSWORD_SUCCESS,
                })

            } else {
                dispatch({
                    type: actionTypes.FORGET_PASSWORD_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: actionTypes.FORGET_PASSWORD_FAIL
            })
        }
    }
}

export function resetPassword(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.RESET_PASSWORD_REQUEST
        })
        try {
            const user = await Client.resetPassword(info);
            
            if (user && user.id) {
                dispatch({
                    type: actionTypes.RESET_PASSWORD_SUCCESS,
                })

            } else {
                dispatch({
                    type: actionTypes.RESET_PASSWORD_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: actionTypes.RESET_PASSWORD_FAIL
            })
        }
    }
}

export function receiveEmail(id, value) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.RECEIVE_EMAIL_REQUEST
        })
        try {
            const user = await Client.receiveEmail(id, value);
            if (user && user.id) {
                dispatch({
                    type: actionTypes.RECEIVE_EMAIL_SUCCESS,
                    data: user
                })

            } else {
                dispatch({
                    type: actionTypes.RECEIVE_EMAIL_FAIL
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: actionTypes.RECEIVE_EMAIL_FAIL
            })
        }
    }
}


