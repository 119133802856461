import actionTypes from '../action_types';
import {combineReducers} from 'redux';

function overview(state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_COMPANY_REVENUE:
            return action.data;
        case actionTypes.GET_REVENUE_OVERVIEW_BY_BRANCHID:
            return action.data;
        case actionTypes.GET_REVENUE_OVERVIEW_OF_SHOP:
            return action.data;
        default:
            return state;
    }
    return state;
}

function overview2(state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_COMPANY_REVENUE_PERIOD:
            return action.data;
        case actionTypes.GET_REVENUE_OVERVIEW_PERIOD_BY_BRANCHID:
            return action.data;
        case actionTypes.GET_REVENUE_OVERVIEW_PERIOD_OF_SHOP:
            return action.data;
        default:
            return state;
    }  
    return state;
}

function target (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_TARGET:
            return action.data;
        case actionTypes.GET_TARGET_BY_BRANCHID:
            return action.data;
        case actionTypes.GET_TARGET_OF_SHOP:{
            let object = {};
            object[action.data.shop_id] = action.data;
            return object;
        }
        default:
            return state;
    }

    return state;
}

const stateRevenue = {
    branch: {
        day: [],
        month: {}
    },
    service: {
        day: [],
        month: {}
    },
    shop: {
        day: [],
        month: [],
    }
}

function revenue (state = stateRevenue, action) {
    switch (action.type) {
        case actionTypes.GET_BRANCH_REVENUE:
            return {...state, branch: { day: action.data, month: action.month}};

        case actionTypes.GET_SERVICE_REVENUE:
            return {...state, service: { day: action.data, month: action.month}};

        case actionTypes.GET_BRANCH_REVENUE_BY_MONTH:
            return {...state, branch_month: action.data}

        case actionTypes.GET_SERVICE_REVENUE_BY_MONTH:
            return {...state, service_month: action.data}

        case actionTypes.GET_SERVICE_REVENUE_TOTAL_BY_BRANCHID: 
            return {...state, service_month: action.data}

        case actionTypes.GET_SERVICE_REVENUE_BY_BRANCHID:
            return {...state, service: { day: action.data, month: action.month}}
        case actionTypes.GET_REVENUE_DAY_OF_SHOP:
            return {...state, shop: { day: action.data }}
        default:
            return state;

    }
    return state;
}

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {
        case actionTypes.EXPORT_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EXPORT_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EXPORT_FAIL: 
            return { isSaved: true, isSuccess: false }
            case actionTypes.IMPORT_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.IMPORT_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.IMPORT_FAIL: 
            return { isSaved: true, isSuccess: false }
    }
    return state;
}

export default combineReducers({
    overview,
    overview2,
    target,
    revenue,
    actions,
})
