import actionTypes from '../action_types';
import {combineReducers} from 'redux';
import { toMap, branchTargetToMap } from '../../../until/toMap';

function branches(state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_BRANCHES: 
            return toMap(action.data);
        default:
            return state;
    }

    return state;
}

function branchTarget(state = { targets: {}, isSaved: false }, action) {
    switch (action.type) {
        case actionTypes.GET_BRANCH_TARGET:
            return { ...state, targets: branchTargetToMap(action.data)};
        case actionTypes.SAVE_BRANCH_TARGET_REQUEST: 
            return { ...state, isSaved: false };
        case actionTypes.SAVE_BRANCH_TARGET:
            return { targets: branchTargetToMap(action.data), isSaved: true }
        default:
            return state;
    }

    return state;
}

function revenues ( state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_REVENUE_OF_BRANCH:
            return action.data;
        default:
            return state;
    }

    return state;
}

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {
        case actionTypes.ADD_BRANCH_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.ADD_BRANCH_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.ADD_BRANCH_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.EDIT_BRANCH_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EDIT_BRANCH_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EDIT_BRANCH_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.DELETE_BRANCH_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.DELETE_BRANCH_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.DELETE_BRANCH_FAIL: 
            return { isSaved: true, isSuccess: false }
        default:
            return {...state};
    }
    return state;
}

export default combineReducers({
    branches,
    branchTarget,
    revenues,
    actions
})
