// import keyMirror from '../../utils/key_mirror';

const actionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    
    GET_ALL_USER: 'GET_ALL_USER',
    GET_USER: 'GET_USER',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAIL: 'CREATE_USER_FAIL',
    
    CHANGE_ROLE_REQUEST: 'CHANGE_ROLE_REQUEST',
    CHANGE_ROLE_SUCCESS: 'CHANGE_ROLE_SUCCESS',
    CHANGE_ROLE_FAIL: 'CHANGE_ROLE_FAIL',
    
    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAIL: 'EDIT_USER_FAIL',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAIL: 'DELETE_USER_FAIL',
    
    ACTIVATE_USER_REQUEST: 'ACTIVATE_USER_REQUEST',
    ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_FAIL: 'ACTIVATE_USER_FAIL',
    
    FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
    FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
    FORGET_PASSWORD_FAIL: 'FORGET_PASSWORD_FAIL',
    
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
    
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

    GET_BRANCHES: 'GET_BRANCHES',
    GET_BRANCH_TARGET: 'GET_BRANCH_TARGET',
    SAVE_BRANCH_TARGET_REQUEST: 'SAVE_BRANCH_TARGET_REQUEST',
    SAVE_BRANCH_TARGET: 'SAVE_BRANCH_TARGET',
    GET_REVENUE_OF_BRANCH: 'GET_REVENUE_OF_BRANCH',
    
    ADD_BRANCH_REQUEST: 'ADD_BRANCH_REQUEST',
    ADD_BRANCH_SUCCESS: 'ADD_BRANCH_SUCCESS',
    ADD_BRANCH_FAIL: 'ADD_BRANCH_FAIL',

    EDIT_BRANCH_REQUEST: 'EDIT_BRANCH_REQUEST',
    EDIT_BRANCH_SUCCESS: 'EDIT_BRANCH_SUCCESS',
    EDIT_BRANCH_FAIL: 'EDIT_BRANCH_FAIL',

    DELETE_BRANCH_REQUEST: 'DELETE_BRANCH_REQUEST',
    DELETE_BRANCH_SUCCESS: 'DELETE_BRANCH_SUCCESS',
    DELETE_BRANCH_FAIL: 'DELETE_BRANCH_FAIL',
    
    GET_ALL_SHOPS: 'GET_ALL_SHOPS',
    GET_SHOPS: 'GET_SHOPS',
    GET_SERVICES_OF_SHOP: 'GET_SERVICES_OF_SHOP',
    GET_SHOP_REVENUE: 'GET_SHOP_REVENUE',
    GET_SHOP_CUSTOMER: 'GET_SHOP_CUSTOMER',
    SAVE_CUSTOMER: 'SAVE_CUSTOMER',
    GET_OTHER_SHOPS: 'GET_OTHER_SHOPS',
    GET_SERVICES_OF_OTHER_SHOP: 'GET_SERVICES_OF_OTHER_SHOP',
    GET_REVENUE_OF_OTHER_SHOP_SUCCESS: 'GET_REVENUE_OF_OTHER_SHOP_SUCCESS',
    GET_REVENUE_OF_OTHER_SHOP_REQUEST: 'GET_REVENUE_OF_OTHER_SHOP_REQUEST',

    ADD_SHOP_REQUEST: 'ADD_SHOP_REQUEST',
    ADD_SHOP_SUCCESS: 'ADD_SHOP_SUCCESS',
    ADD_SHOP_FAIL: 'ADD_SHOP_FAIL',

    EDIT_SHOP_REQUEST: 'EDIT_SHOP_REQUEST',
    EDIT_SHOP_SUCCESS: 'EDIT_SHOP_SUCCESS',
    EDIT_SHOP_FAIL: 'EDIT_SHOP_FAIL',

    DELETE_SHOP_REQUEST: 'DELETE_SHOP_REQUEST',
    DELETE_SHOP_SUCCESS: 'DELETE_SHOP_SUCCESS',
    DELETE_SHOP_FAIL: 'DELETE_SHOP_FAIL',

    GET_CIPS: 'GET_CIPS',
    SAVE_CIPS_REQUEST: 'SAVE_CIPS_REQUEST',
    SAVE_CIPS_SUCCESS: 'SAVE_CIPS_SUCCESS',
    SAVE_CIPS_FAIL: 'SAVE_CIPS_FAIL',
    GET_CIP_REVENUES: 'GET_CIP_REVENUES',

    GET_LIST_PARTNERS: 'GET_LIST_PARTNERS',
    ADD_PARTNER_REQUEST: 'ADD_PARTNER_REQUEST',
    ADD_PARTNER: 'ADD_PARTNER',
    GET_PARTNERS: 'GET_PARTNERS',
    REMOVE_PARTNER: 'REMOVE_PARTNER',
    EDIT_PARTNER_REQUEST: 'EDIT_PARTNER_REQUEST',
    EDIT_PARTNER_SUCCESS: 'EDIT_PARTNER_SUCCESS',
    EDIT_PARTNER_FAIL: 'EDIT_PARTNER_FAIL',
    
    GET_SERVICES: 'GET_SERVICES',
    GET_SERVICE_GROUPS: 'GET_SERVICE_GROUPS',
    
    SAVE_CUSTOMER_REQUEST: 'SAVE_CUSTOMER_REQUEST',
    SAVE_CUSTOMER_SUCCESS: 'SAVE_CUSTOMER_SUCCESS',
    SAVE_CUSTOMER_FAIL: 'SAVE_CUSTOMER_FAIL',

    SAVE_REVENUE_REQUEST: 'SAVE_REVENUE_REQUEST',
    SAVE_REVENUE_SUCCESS: 'SAVE_REVENUE_SUCCESS',
    SAVE_REVENUE_FAIL: 'SAVE_REVENUE_FAIL',

    ADD_SERVICE_REQUEST: 'ADD_SERVICE_REQUEST',
    ADD_SERVICE_SUCCESS: 'ADD_SERVICE_SUCCESS',
    ADD_SERVICE_FAIL: 'ADD_SERVICE_FAIL',

    EDIT_SERVICE_REQUEST: 'EDIT_SERVICE_REQUEST',
    EDIT_SERVICE_SUCCESS: 'EDIT_SERVICE_SUCCESS',
    EDIT_SERVICE_FAIL: 'EDIT_SERVICE_FAIL',
    
    DELETE_SERVICE_REQUEST: 'DELETE_SERVICE_REQUEST',
    DELETE_SERVICE_SUCCESS: 'DELETE_SERVICE_SUCCESS',
    DELETE_SERVICE_FAIL: 'DELETE_SERVICE_FAIL',

    ADD_SERVICE_GROUP_REQUEST: 'ADD_SERVICE_GROUP_REQUEST',
    ADD_SERVICE_GROUP_SUCCESS: 'ADD_SERVICE_GROUP_SUCCESS',
    ADD_SERVICE_GROUP_FAIL: 'ADD_SERVICE_GROUP_FAIL',

    EDIT_SERVICE_GROUP_REQUEST: 'EDIT_SERVICE_GROUP_REQUEST',
    EDIT_SERVICE_GROUP_SUCCESS: 'EDIT_SERVICE_GROUP_SUCCESS',
    EDIT_SERVICE_GROUP_FAIL: 'EDIT_SERVICE_GROUP_FAIL',
    
    DELETE_SERVICE_GROUP_REQUEST: 'DELETE_SERVICE_GROUP_REQUEST',
    DELETE_SERVICE_GROUP_SUCCESS: 'DELETE_SERVICE_GROUP_SUCCESS',
    DELETE_SERVICE_GROUP_FAIL: 'DELETE_SERVICE_GROUP_FAIL',

    SAVE_OTHER_REVENUE_REQUEST: 'SAVE_OTHER_REVENUE_REQUEST',
    SAVE_OTHER_REVENUE_SUCCESS: 'SAVE_OTHER_REVENUE_SUCCESS',
    SAVE_OTHER_REVENUE_FAIL: 'SAVE_OTHER_REVENUE_FAIL',

    SAVE_FLIGHT_REQUEST: 'SAVE_FLIGHT_REQUEST',
    SAVE_FLIGHT_SUCCESS: 'SAVE_FLIGHT_SUCCESS',
    SAVE_FLIGHT_FAIL: 'SAVE_FLIGHT_FAIL',
    GET_FLIGHT: 'GET_FLIGHT',

    GET_COMPANY_REVENUE: 'GET_COMPANY_REVENUE',
    GET_COMPANY_REVENUE_PERIOD: 'GET_COMPANY_REVENUE_PERIOD',
    GET_TARGET: 'GET_TARGET',
    GET_BRANCH_REVENUE: 'GET_BRANCH_REVENUE',
    GET_SERVICE_REVENUE: 'GET_SERVICE_REVENUE',
    GET_BRANCH_REVENUE_BY_MONTH: 'GET_BRANCH_REVENUE_BY_MONTH',
    GET_SERVICE_REVENUE_BY_MONTH: 'GET_SERVICE_REVENUE_BY_MONTH',
    GET_REVENUE_OVERVIEW_BY_BRANCHID: 'GET_REVENUE_OVERVIEW_BY_BRANCHID',
    GET_REVENUE_OVERVIEW_PERIOD_BY_BRANCHID: 'GET_REVENUE_OVERVIEW_PERIOD_BY_BRANCHID',
    GET_TARGET_BY_BRANCHID: 'GET_TARGET_BY_BRANCHID',
    GET_SERVICE_REVENUE_BY_BRANCHID: 'GET_SERVICE_REVENUE_BY_BRANCHID',
    GET_SERVICE_REVENUE_TOTAL_BY_BRANCHID: 'GET_SERVICE_REVENUE_TOTAL_BY_BRANCHID',
    
    GET_REVENUE_OVERVIEW_OF_SHOP: 'GET_REVENUE_OVERVIEW_OF_SHOP',
    GET_REVENUE_OVERVIEW_PERIOD_OF_SHOP: 'GET_REVENUE_OVERVIEW_PERIOD_OF_SHOP',
    GET_REVENUE_DAY_OF_SHOP: 'GET_REVENUE_DAY_OF_SHOP',
    GET_TARGET_OF_SHOP: 'GET_TARGET_OF_SHOP',
    
    CHANGE_BRANCH_ID: 'CHANGE_BRANCH_ID',

    EXPORT_REQUEST: 'EXPORT_REQUEST',
    EXPORT_SUCCESS: 'EXPORT_SUCCESS',
    EXPORT_FAIL: 'EXPORT_FAIL',

    IMPORT_REQUEST: 'IMPORT_REQUEST',
    IMPORT_SUCCESS: 'IMPORT_SUCCESS',
    IMPORT_FAIL: 'IMPORT_FAIL',

    RECEIVE_EMAIL_REQUEST: 'RECEIVE_EMAIL_REQUEST',
    RECEIVE_EMAIL_SUCCESS: 'RECEIVE_EMAIL_SUCCESS',
    RECEIVE_EMAIL_FAIL: 'RECEIVE_EMAIL_FAIL',

    GET_TIME_SEND_EMAIL_REQUEST: 'GET_TIME_SEND_EMAIL_REQUEST',
    GET_TIME_SEND_EMAIL_SUCCESS: 'GET_TIME_SEND_EMAIL_SUCCESS',
    GET_TIME_SEND_EMAIL_FAIL: 'GET_TIME_SEND_EMAIL_FAIL',

    SET_TIME_SEND_EMAIL_REQUEST: 'SET_TIME_SEND_EMAIL_REQUEST',
    SET_TIME_SEND_EMAIL_SUCCESS: 'SET_TIME_SEND_EMAIL_SUCCESS',
    SET_TIME_SEND_EMAIL_FAIL: 'SET_TIME_SEND_EMAIL_FAIL',
}

export default actionTypes;