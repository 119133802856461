import actionTypes from '../action_types';
import {combineReducers} from 'redux';

function time(state = { status: 'not_started' }, action) {
    switch (action.type) {
        case actionTypes.GET_TIME_SEND_EMAIL_SUCCESS:
            return Object.assign({}, state, action.data);

        case actionTypes.SET_TIME_SEND_EMAIL_REQUEST:
            return Object.assign({}, state,  { status: 'started'});

        case actionTypes.SET_TIME_SEND_EMAIL_SUCCESS:
            return Object.assign({}, { status: 'success'}, action.data);

        case actionTypes.SET_TIME_SEND_EMAIL_FAIL:
            return Object.assign({}, { status: 'failure'}, action.data);

        default:
            return state;
    }
    return state;
}

export default combineReducers({
    time
})
