import {combineReducers} from 'redux';
import actionTypes from '../action_types';
import { toMap, servicesToMap, customerToMap, revenueToMap } from '../../../until/toMap';

function allShops (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_ALL_SHOPS: {
            // const data = action.data.filter(a => !a.deleted_at)
            return toMap(action.data);
        }
        default:
            return state;
    }
    return state;
}

function shops (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_SHOPS:{
            const data = action.data.filter(a => !a.deleted_at)
            return toMap(data);
        }
        default:
            return state;
    }
    return state;
}
 
function services (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICES_OF_SHOP:
            return servicesToMap(action.data);
        default: 
            return state;
    }
    return state;
}

function revenues (state = {}, action) {
    switch (action.type) {
        case actionTypes.GET_SHOP_REVENUE: 
            return revenueToMap(action.data);
        default:
            return state;     
    }
    return state;
}

function customers (state = {saved: {isSaved: false, isSuccess: false}, info: {}}, action) {
    switch (action.type) {
        case actionTypes.GET_SHOP_CUSTOMER: 
            return {...state, info: customerToMap(action.data)};
        case actionTypes.SAVE_CUSTOMER_REQUEST:
            return {...state, saved: {isSaved: false, isSuccess: false}};
        case actionTypes.SAVE_CUSTOMER_SUCCESS:
            return { saved: { isSaved: true, isSuccess: true }, info: customerToMap(action.data)};
        case actionTypes.SAVE_CUSTOMER_FAIL:
            return {...state, saved: { isSaved: true, isSuccess: false }};
        default:
            return {...state};     
    }
    return state;
}

function actions ( state = { isSaved: false, isSuccess: false}, action) {
    switch (action.type) {
        case actionTypes.ADD_SHOP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.ADD_SHOP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.ADD_SHOP_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.EDIT_SHOP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.EDIT_SHOP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.EDIT_SHOP_FAIL: 
            return { isSaved: true, isSuccess: false }

        case actionTypes.DELETE_SHOP_REQUEST:
            return { isSaved: false, isSuccess: false };
        case actionTypes.DELETE_SHOP_SUCCESS: 
            return { isSaved: true, isSuccess: true };
        case actionTypes.DELETE_SHOP_FAIL: 
            return { isSaved: true, isSuccess: false }
        default:
            return {...state};
    }
    return state;
}

export default combineReducers({
    allShops,
    shops,
    services,
    revenues,
    customers,
    actions
})