import Client from '../client';
import actionTypes from '../action_types';

export function getAllShops() {
    return async (dispatch) => {
        try {
            const data = await Client.getAllShops();
            dispatch({
                type: actionTypes.GET_ALL_SHOPS,
                data,
            })
        
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}
export function getShopsByBranchId(branchId) { 
    return async (dispatch) => {
        try {
            const data = await Client.getShops(branchId);
            dispatch({
                type: actionTypes.GET_SHOPS,
                data,
            })
            
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getServicesByShopId(shopId) {
    if (!shopId) {
        return dispatch => {
            dispatch({
                type: actionTypes.GET_SERVICES_OF_SHOP,
                data: [],
            })
        }
    }

    return async (dispatch) => {
        try {
            const data = await Client.getServicesByShopId(shopId);
            dispatch({
                type: actionTypes.GET_SERVICES_OF_SHOP,
                data,
            })
            
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getShopRevenue(shopId, date) {
    if (!shopId) {
        return dispatch => {
            dispatch({
                type: actionTypes.GET_SHOP_REVENUE,
                data: [],
            })
        }
    }
    return async (dispatch) => {
        try {
            const data = await Client.getShopRevenue(shopId, date);
            
            if(Array.isArray(data)) {
                dispatch({
                    type: actionTypes.GET_SHOP_REVENUE,
                    data
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function getShopCustomer(shopId, date) {
    if (!shopId) {
        return dispatch => {
            dispatch({
                type: actionTypes.GET_SHOP_CUSTOMER,
                data: [],
            })
        }
    }
    return async (dispatch) => {
        try {
            const data = await Client.getShopCustomer(shopId, date);
            if(Array.isArray(data)) {
                dispatch({
                    type: actionTypes.GET_SHOP_CUSTOMER,
                    data
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function addPartnerToShop(shopId) {
    return async (dispatch) => {
        try {
            const data = await Client.addPartner(shopId);
            dispatch({
                type: actionTypes.ADD_PARTNER,
                data,
            })
            
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
            
        }
    }
}

export function addShop(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_SHOP_REQUEST,
        })
        try {
            const dt = await Client.addShop(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.ADD_SHOP_SUCCESS,
                })
                const data = await Client.getAllShops();
                dispatch({
                    type: actionTypes.GET_ALL_SHOPS,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.ADD_SHOP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
            
        }
    }
}

export function editShop(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.EDIT_SHOP_REQUEST,
        })
        try {
            const dt = await Client.editShop(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.EDIT_SHOP_SUCCESS,
                })
                const data = await Client.getAllShops();
                dispatch({
                    type: actionTypes.GET_ALL_SHOPS,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.EDIT_SHOP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}

export function deleteShop(info) {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_SHOP_REQUEST,
        })
        try {
            const dt = await Client.deleteShop(info);
            if (typeof dt == 'object') {
                dispatch({
                    type: actionTypes.DELETE_SHOP_SUCCESS,
                })
                const data = await Client.getAllShops();
                dispatch({
                    type: actionTypes.GET_ALL_SHOPS,
                    data,
                })
            } else {
                dispatch({
                    type: actionTypes.DELETE_SHOP_FAIL,
                })
            }
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: 'ERROR',
            })
        }
    }
}